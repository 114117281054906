.preloader {
	max-width: 100vw;
	max-height: 100vh;
	background: #222;
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.loader {
	width: 100vw;
	height: 100vh;
	color: #222;
	overflow: hidden;
	& > * {
		transform: scale(4);
	}
}
.loader > * {
	@include respond(desktop) {
		transform: scale(7);
	}
	@include respond(tablet) {
		transform: scale(4);
	}
	@include respond(mobile-landscape) {
		transform: scale(4);
	}
	@include respond(mobile) {
		transform: scale(4);
	}
	@include respond(mobile-small) {
		transform: scale(4);
	}
}
