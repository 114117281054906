.greeting-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	& h1 {
		@include respond(desktop) {
			letter-spacing: 2px;
			font-size: 4.5rem;
		}
		@include respond(tablet) {
			font-size: 3.5rem;
		}
		@include respond(mobile-landscape) {
			font-size: 2.8rem;
		}
		@include respond(mobile) {
			font-size: 2.1rem;
		}
		@include respond(mobile-small) {
			font-size: 1.6rem;
		}
	}
}
