.post-wrapper {
	display: grid;
	grid-template-columns: repeat(auto-fit, 320px);
	gap: 20px;
	justify-content: center;
	padding-bottom: 5rem;
	& .post {
		background-color: $mainColor;
		&:hover .thumbnail {
			transform: scale(1);
		}
		&:hover .languages-container {
			opacity: 1;
			transform: translateX(0);
			background-color: rgba(0, 0, 0, 0.315);
		}
	}
	& .img-container {
		position: relative;
		width: 100%;
		overflow: hidden;
		& .languages-container {
			position: absolute;
			top: 0;
			right: 0;
			transform: translateX(-400px);
			opacity: 1;
			width: 100%;
			height: 100%;
			transition: 0.4s ease;
			display: grid;
			grid-gap: 2px;
			justify-content: space-around;
			grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));

			& p {
				margin: 1rem 0 0 1rem;
				& span {
					font-size: 1.4rem;
					background-color: $mainColor;
					font-family: "Russo One", sans-serif;
					letter-spacing: 2px;
					padding: 0.3rem 0.7rem;
					color: $mainText;
				}
			}
		}
	}
	& .thumbnail {
		display: block;
		width: 100%;
		height: 180px;
		object-fit: cover;
		transform: scale(1.2);
		transition: 0.2s ease;
		backface-visibility: hidden;
	}
	& .post {
		border: 1px solid $borderColor;
		text-overflow: ellipsis;
	}
	.post-preview {
		background-color: $secondaryColor;
		padding: 15px 15px 0 15px;
	}
	& .post-title {
		color: $mainText;
		margin: 0;
		font-size: 2.2rem;
	}
	.post-intro {
		display: -webkit-box;
		color: $secondaryText;
		font-size: 1.6rem;
		height: 13rem;
		overflow: hidden;
		text-overflow: ellipsis;
		border-bottom: 1px solid #777777;
	}
	.project-links-container ul {
		display: flex;
		height: 2rem;
		font-size: 16px;
		flex-direction: row;
		justify-content: center;
		margin-left: 0;
		padding-inline-start: 0;
		& li {
			margin: 0 10px;
		}
	}
}
