.nav-wrapper {
	grid-area: nav-wrapper;
	border-bottom: 1px solid $borderColor;
	border-radius: 5px 5px 0 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: $mainColor;
	#navigation {
		margin: 0;
		padding: 10px;
		& li {
			display: inline-block;
			margin: 0px 0.5rem;
		}
	}

	& .dots-wrapper {
		display: flex;
		padding: 10px;
		& .browser-dot {
			background-color: black;
			height: 15px;
			width: 15px;
			border-radius: 50%;
			margin: 0px 5px;
			-webkit-box-shadow: $boxShadow;
			-moz-box-shadow: $boxShadow;
			box-shadow: $boxShadow;
		}
		& #dot-1 {
			background-color: #fc6058;
		}
		& #dot-2 {
			background-color: #fec02f;
		}
		& #dot-3 {
			background-color: #2aca3e;
		}
	}
}
