.left-column {
	grid-area: left-column;
	padding: 50px 0;
	.profile-container {
		display: flex;
		justify-content: center;
	}
	& #profile_pic {
		height: 200px;
		width: 200px;
		object-fit: cover;
		border: 2px solid $borderColor;
		@include respond(tablet) {
			height: 180px;
			width: 180px;
		}
	}
	& .text-heading {
		font-size: 2rem;
		@include respond(tablet) {
			font-size: 1.8rem;
		}
		@include respond(mobile-landscape) {
			font-size: 1.6rem;
		}
	}
	& .theme-options-wrapper {
		display: flex;
		justify-content: center;
		height: 5rem;
		& .theme-dot {
			height: 30px;
			width: 30px;
			margin: 5px;
			border: 2px solid $themeDotBorder;
			border-radius: 50%;
			box-shadow: $boxShadow;
			cursor: pointer;
			&:hover,
			&:active,
			&:focus {
				border-width: 0.5rem;
			}
			transition: 0.3s ease;
		}
		#light-mode {
			background-color: rgb(240, 240, 240);
		}
		#dark-mode {
			background-color: #192734;
		}
		#green-mode {
			background-color: #78866b;
		}
		#purple-mode {
			background-color: #7e4674;
		}
		#red-mode {
			background-color: #b73225;
		}
	}
	.settings-note {
		font-size: 1.4rem;
		font-style: italic;
		text-align: center;
		@include respond(tablet) {
			font-size: 1.1rem;
		}
		@include respond(mobile-landscape) {
			font-size: 0.9rem;
		}
		@include respond(mobile) {
			font-size: 0.8rem;
		}
	}
}
