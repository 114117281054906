@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);
// Variables
$regal-blue: #034378;
$san-juan: #2d4e68;
$bermuda: #77d7b9;
$white: #fff;
$black: rgb(12, 12, 12);
$open-sans: "Open Sans", sans-serif;
// clear-fix mixin
@mixin cf {
	&::before,
	&::after {
		content: "";
		display: table;
	}
	&::after {
		clear: both;
	}
}
// Base styles
.card {
	width: 100%;
	height: auto;
	z-index: 10;
	.menu-content {
		@include cf;
		margin: 0;
		padding: 0;
		list-style-type: none;
		span {
			position: absolute;
			left: 50%;
			top: 0;
			font-size: 10px;
			font-weight: 700;
			font-family: "Open Sans";
			transform: translate(-50%, 0);
		}
	}
	.wrapper {
		background-color: $white;
		height: 48rem;
		position: relative;
		overflow: hidden;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		&:hover {
			.data {
				transform: translateY(0);
			}
		}
		box-sizing: border-box;

		// breakpoints for height

		// @media only screen and (max-width: 1744px) {
		// 	background-color: red;
		// 	height: 500px;
		// }
		// @media only screen and (max-width: 1619px) {
		// 	background-color: blue;
		// 	height: 525px;
		// }
		// @media only screen and (max-width: 1376px) {
		// 	background-color: green;
		// 	height: 546px;
		// }
		// @media only screen and (max-width: 1148px) {
		// 	background-color: yellow;
		// 	height: 566px;
		// }
	}

	.data {
		position: absolute;
		bottom: 0;
		transform: translateY(calc(110px + 1em));
		transition: transform 0.3s;
		.content {
			padding: 1em;
			position: relative;
			z-index: 1;
			background-color: $mainColor;
		}
	}
	.blog-title * {
		margin-top: 1;
		color: $mainText !important;
	}
	.text {
		margin: 0;
		line-clamp: 4;
		text-overflow: ellipsis;
		color: $mainText;
	}
}
.blog-card {
	.date {
		position: absolute;
		top: 0;
		left: 0;
		background-color: $mainColor;
		color: $mainText;
		padding: 0.8em;
		span {
			display: block;
			text-align: center;
		}
		.day {
			font-weight: 700;
			font-size: 2.4rem;
			text-shadow: 2px 3px 2px rgba($black, 0.18);
		}
		.month {
			text-transform: uppercase;
		}
		.month,
		.year {
			font-size: 1.2rem;
		}
	}
	.content {
		background-color: $white;
		box-shadow: 0 5px 30px 10px rgba($black, 0.3);
	}
	.blog-title {
		a {
			font-size: 3rem;
			color: $black;
		}
	}
}
.carousel__slide--visible {
	@media only screen and (max-width: 1744px) {
		// background-color: red !important;
		height: 500px !important;
	}
	@media only screen and (max-width: 1619px) {
		// background-color: blue !important;
		height: 525px !important;
	}
	@media only screen and (max-width: 1376px) {
		// background-color: green !important;
		height: 546px !important;
	}
	@media only screen and (max-width: 1148px) {
		// background-color: yellow !important;
		height: 526px !important;
	}
}
