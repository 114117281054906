$mainColor: #15202b;
$secondaryColor: #192734;

$borderColor: #fdfdfd;
$mainText: #fff;
$secondaryText: #bbbbbb;
$secondaryText-light: #888;

/* Box Shadow */
$boxShadow: -1px 1px 3px -1px rgba(0, 0, 0, 0.75);
$themeDotBorder: #fff;
$previewBg: rgba(25, 39, 52, 0.8);
$previewShadow: #161b20;
$buttonColor: #192734;
$buttonColorLight: #2b3f52;
$link-Color: #00ddff;
// Social Icon Colors
