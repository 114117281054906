@mixin respond($point) {
	@if $point == desktop {
		@media (max-width: 1199.98px) {
			@content;
		}
	} @else if $point == tablet {
		@media (max-width: 991.98px) {
			@content;
		}
	} @else if $point == mobile-landscape {
		@media (max-width: 688px) {
			@content;
		}
	} @else if $point == mobile {
		@media (max-width: 575.98px) {
			@content;
		}
	} @else if $point == mobile-small {
		@media (max-width: 420.98px) {
			@content;
		}
	}
}

// Usage
/* 

.element {
    @include respond(mobile) {
        width: 100%;
	}
}

*/
