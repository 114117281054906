.social-links {
	display: flex;
	flex-direction: column;
	& h2 {
		@include respond(mobile-landscape) {
			font-size: 3rem;
		}
	}
	&__container {
		display: grid;
		align-items: center;
		grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
		& a {
			font-size: 2.5rem;
			&::after {
				transform: scaleX(0);
			}

			&:not(:last-child) {
				margin-right: 2rem;
			}
			@include respond(mobile-landscape) {
				font-size: 2rem;
			}
			& svg {
				border: 2px solid $mainText;
				padding: 1.2rem;
				color: $secondaryText;
				background: transparent;
				border-radius: 40%;
				transition: all 0.2s linear;
				&:hover {
					border-radius: 0;
					color: $mainColor;
					background-color: $secondaryText;
				}
			}
		}

		& a.GitHub svg {
		}
		& a.LinkedIn svg {
		}
		& a.Twitter svg {
		}
		& a.Youtube svg {
		}
		& a.Medium svg {
		}
		& a.Reddit svg {
		}
		& a.HackerRank svg {
		}
		& a.Topcoder svg {
		}
		& a.Facebook svg {
		}
		& a.Quora svg {
		}
		& a.Behance svg {
		}
		& a.Dribbble svg {
		}
		& a.Twitch svg {
		}
		& a.Vimeo svg {
		}
	}
}
