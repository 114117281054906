.about-wrapper-main {
	position: relative;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
	padding: 5rem;
	grid-gap: 5rem;

	@include respond(tablet) {
		padding: 1rem;
	}
	@include respond(mobile) {
		grid-gap: 0rem;
	}
	& p {
		font-size: 1.6rem;
		@include respond(tablet) {
			font-size: 1.2rem;
			display: flex !important;
			flex-direction: row !important;
		}
	}
}
