*::selection {
	background-color: $mainText;
	color: $mainColor;
}
html,
body {
	padding: 0;
	margin: 0;
	scroll-behavior: smooth;
	font-size: 62.5%;
}
h1,
h2,
h3,
h4,
h5,
h6,
strong,
.heading {
	color: $mainText;
	font-family: "Russo One", sans-serif;
	font-weight: 500;
}
p,
li,
span,
label,
input,
textarea {
	color: $mainText;
	font-family: "Roboto Mono", monospace;
}
h1 {
	font-size: 5.6rem;
}

h2 {
	font-size: 3.6rem;
}
h3 {
	font-size: 2.8rem;
}
h4 {
	font-size: 2.4rem;
}
h5 {
	font-size: 2rem;
}
h6 {
	font-size: 1.6rem;
}
a {
	text-decoration: none;
	position: relative;
	display: inline-block;
	color: $link-Color;
	font-size: 2rem;
	@include respond(desktop) {
		font-size: 2rem;
	}
	@include respond(tablet) {
		font-size: 1.6rem;
	}
	&:after {
		display: block;
		content: "";
		bottom: 0;
		left: 0;
		width: 20%;
		border-bottom: solid 3px $link-Color;
		transition: all 250ms ease-in-out;
	}
	&:hover:after {
		width: 100%;
	}
}
ul {
	list-style: none;
	padding-inline-start: 0px;
}
.s1 {
	background-color: $mainColor;
	border-bottom: 1px solid $borderColor;
	overflow: auto;
}
.s2 {
	background-color: $secondaryColor;
	border-bottom: 1px solid $borderColor;
	overflow: auto;
}
.main-container {
	width: 120rem;
	margin: 0 auto;
	@include respond(desktop) {
		width: 95%;
	}
}
.awssld__content {
	background-color: transparent !important;
}
.Tilt {
	cursor: cell;
}
