#contact-form {
	display: block;
	max-width: 800px;
	margin: 0 auto;
	border: 1px solid $borderColor;
	padding: 2.5rem;
	border-radius: 5px;
	background-color: $mainColor;
	margin-bottom: 50px;
	& .input-field {
		width: 100%;
		height: 4rem;
		background-color: $secondaryColor;
		border-radius: 0.5rem;
		border: 1px solid $borderColor;
		font-size: 1.4rem;
		margin-top: 0.8rem;
		resize: none;
		box-sizing: border-box;
		padding-left: 1rem;
		&::placeholder {
			color: $secondaryText-light;
		}
	}
	& .contact-wrapper {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-areas:
			"name email"
			"message message";
		grid-gap: 4rem;
		& label {
			font-size: 2rem;
		}
	}
	& .name {
		grid-area: name;
	}
	& .email {
		grid-area: email;
	}
	& .message {
		grid-area: message;
	}
	& textarea {
		min-height: 10rem;
	}
	#submit-btn {
		margin-top: 1rem;
		width: 100%;
		padding: 1rem 0;
		color: #fff;
		background-color: $buttonColor;
		border: none;
		cursor: pointer;
		font-size: 16px;
	}
	#submit-btn:hover,
	#submit-btn:active {
		background-color: $buttonColorLight;
	}
}
