.right-column {
	grid-area: right-column;
	display: grid;
	justify-content: center;
	align-content: center;
	padding: 7rem 0px 3rem 0px;
	@include respond(mobile-landscape) {
		padding-top: 2rem;
	}

	@include respond(tablet) {
		justify-content: center;
	}
	& .preview-shadow {
		background-color: $previewShadow;
		width: 300px;
		height: 180px;
		padding: 10px 0 0 30px;
		transform: translateY(-20%);
		@include respond(tablet) {
			width: 28rem;
			height: 16rem;
		}
		@include respond(mobile-landscape) {
			width: 27rem;
			height: 14rem;
		}
		& .preview {
			width: 28rem;
			border: 1.5px solid #17a2b8;
			background-color: $previewBg;
			padding: 1.5rem;
			position: relative;
			@include respond(tablet) {
				width: 28rem;
				padding: 1rem;
			}
			& .corner {
				position: absolute;
				height: 7px;
				width: 7px;
				border-radius: 50%;
				background-color: #fff;
				border: 1.5px solid #17a2b8;
			}
			& .corner:nth-child(1) {
				top: -5px;
				left: -5px;
			}
			& .corner:nth-child(2) {
				top: -5px;
				right: -5px;
			}
			& .corner:nth-child(3) {
				bottom: -5px;
				right: -5px;
			}
			& .corner:nth-child(4) {
				bottom: -5px;
				left: -5px;
			}
			& p {
				font-size: 1.6rem;
				@include respond(tablet) {
					font-size: 1.2rem;
				}
			}
		}
	}
}
/* TL TR BR BL Corners */

/* More About Me section  */
