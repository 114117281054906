.intro-wrapper {
	background-color: $secondaryColor;
	border: 1px solid $borderColor;
	border-radius: 5px 5px 0 0;

	-webkit-box-shadow: $boxShadow;
	-moz-box-shadow: $boxShadow;
	box-shadow: $boxShadow;

	display: grid;
	grid-template-columns: 1fr 1fr;
	/* grid-template-rows: 3em 30em; */
	grid-template-areas:
		"nav-wrapper nav-wrapper"
		"left-column right-column";
	@include respond(mobile-landscape) {
		grid-template-columns: 1fr;
		grid-template-areas:
			"nav-wrapper"
			"left-column"
			"right-column";
	}
}
