#skills {
	display: flex;
	justify-content: space-around;
	background-color: $mainColor;
	padding: 1rem;
	@include respond(tablet) {
		padding: 0;
	}
	& li {
		font-size: 1.8rem;
		margin: 1rem 0;
		@include respond(tablet) {
			font-size: 1.4rem;
			padding: 0;
		}
	}
}
