.footer-text {
	display: flex;
	align-items: center;
	justify-content: center;
	& h5 {
		letter-spacing: 3px;
		@include respond(tablet) {
			font-size: 1.6rem;
		}
	}
}
